



import Component from 'vue-class-component';
import {Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';

@Component({
  computed: {
    ...mapGetters({
      isAuthenticated: 'authStore/isAuthenticated',
    }),
  }
})
export default class Home extends Vue {

  // DATA

  public receivedHeight: number = 300;

  // MAPPED GETTERS, STATE

  public readonly isAuthenticated: boolean;

  // COMPUTED

  public get iframeSrc(): string {
    let result = '/documents/about/organizers_en.html';

    switch (this.$route.name) {
      case 'home':
        result = '/documents/about/organizers_en.html';
        if (this.$i18n.locale === 'ru') {
          result = '/documents/about/organizers_ru.html';
        }
        break;
      case 'home-forexhibitors':
        result = '/documents/about/exhibitors_en.html';
        if (this.$i18n.locale === 'ru') {
          result = '/documents/about/exhibitors_ru.html';
        }
        break;
      default:
    }

    return result;
  }

  public get aboutIframeHeight(): string {
    return '100vh';
    // return this.receivedHeight === 0 ? '0' : this.receivedHeight.toFixed(0) + 'px';
  }

  public mounted(): void {
    this.attachPostMessageHandler();
    this.addExtraRootElementClasses();
  }

  public beforeDestroy(): void {
    this.removePostMessageHandler();
    this.removeExtraRootElementClasses();
  }

  // METHODS

  private addExtraRootElementClasses(): void {
    document.documentElement.classList.add('root-is-about-landing');
    document.documentElement.classList.add('root-no-persistent-scrollbars');
  }

  private removeExtraRootElementClasses(): void {
    document.documentElement.classList.remove('root-is-about-landing');
    document.documentElement.classList.remove('root-no-persistent-scrollbars');
  }

  private attachPostMessageHandler(): void {
    window.addEventListener('message', this.handlePostMessage, false);
  }

  private removePostMessageHandler(): void {
    window.removeEventListener('message', this.handlePostMessage);
  }

  private handlePostMessage(event: MessageEvent): void {
    if (event && event.data) {
      if (event.data.type === 'ew-about-height') {
        this.receivedHeight = event.data.height;
      } else if (event.data.type === 'ew-about-change-lang') {
        this.handleChangeLanguage(event.data.lang);
      } else if (event.data.type === 'ew-about-change-route') {
        this.handleChangeRoute(event.data.routeName);
      } else if (event.data.type === 'ew-about-scroll-to') {
        this.handleScrollCommand(event.data.coords);
      }
    }
  }

  private handleChangeLanguage(lang: string): void {
    this.receivedHeight = 700;
    this.$router.push({name: this.$route.name, params: {lang: lang}}).catch(() => { /* ignore */ });
  }

  private handleChangeRoute(routeName: string): void {
    this.receivedHeight = 700;
    this.$router.push({name: routeName}).catch(() => { /* ignore */ });
  }

  private handleScrollCommand(coords: {x: number; y: number}): void {
    window.scrollTo(coords.x, coords.y);
  }

}
